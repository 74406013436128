cq-chart-title {
  background-color: transparent !important;
  color: var(--color);
}
cq-clickable {
  color: var(--color);
}

.ciq-nav,
.ciq-footer {
  background-color: var(--secondary_5);
  color: var(--color);
}

::-webkit-scrollbar,
.scroll-y::-webkit-scrollbar {
  background-color: #e5e5e5;
  background-color: var(--secondary_10);
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-button,
.scroll-y::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb,
.scroll-y::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  background-color: var(--secondary_15);
  border: 1px solid #fafafa;
  border: 1px solid var(--secondary_25);
  border-radius: 8px;
}

::-webkit-scrollbar-track,
.scroll-y::-webkit-scrollbar-track {
  background-color: #e5e5e5;
  background-color: var(--secondary_5);
}

html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  overscroll-behavior-y: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: scrollbar;
}

:root,
html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: 22px;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#root.mobile,
#root [aria-modal='true'] {
  max-width: 520px;
  margin: 0 auto;
}

.react-split .full-content {
  display: flex;
}

.scale {
  transform-origin: 0px 0px;
  transform: scale(var(--scale));
}

#search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 250, 250, 0.2);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

#search-modal > * {
  width: 100%;
  max-width: 720px;
  height: 100%;
  min-height: 480px;
  max-height: 80vh;
  background-color: var(--secondary_5);
  border-radius: 16px;
  overflow: hidden;
  padding: 32px !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

select:focus {
  outline: none;
  padding: 0;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  filter: none; /* required for firefox */
  border: 1px solid var(--bg);
  -webkit-text-fill-color: var(--color);
  -webkit-box-shadow: 0 0 0px 1000px var(--bg) inset;
  transition: background-color 5000s ease-in-out 0s;
}

select {
  background: var(--secondary_5) !important;
  color: var(--color) !important;
}

* {
  outline: none;
}


.gutter{
  cursor: move;
}

.gutter-horizontal::after {
    content: '';
    color: var(--secondary_15);
    width: 6px;
    height: 44px;
    top: calc(50% - 22px);
    background: var(--secondary_10);
    position: absolute;
    border-radius: 4px;
    font-size: 14px;
    line-height: 0;
    vertical-align: middle;
    text-align: center;
}

.gutter-vertical::after {
    content: '.....';
    color: var(--secondary_15);
    left: calc(50% - 21px);
    width: 44px;
    background: var(--secondary_10);
    height: 8px;
    position: absolute;
    border-radius: 4px;
    font-size: 14px;
    line-height: 0;
    vertical-align: middle;
    text-align: center;
}

div.splitter > div:nth-child(2) {
  display: none;
}
div:hover > div.splitter > div:nth-child(2){
  display: flex;
}

/* width */
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

:hover::-webkit-scrollbar, :active::-webkit-scrollbar{
  width: 6px;
  height: 6px;
}

div[class*=r-overflowY]:hover::-webkit-scrollbar{
  width: 6px;
}
div[class*=r-overflowX]:hover::-webkit-scrollbar{
  height: 6px;
}

body[dir="ltr"] #padding_on_scroll:hover, body:not([dir]) #padding_on_scroll:hover {
  padding-right: 8px;
}

body[dir="rtl"] #padding_on_scroll:hover {
  padding-left: 8px;
}


/* button */
::-webkit-scrollbar-button {
    background: var(--secondary_5);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--secondary_25);
    opacity: 0.5;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    opacity: 1;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--secondary_5);
}


::-webkit-scrollbar-track-piece {
    background: var(--secondary_5);
}

/* Corner */
::-webkit-scrollbar-corner {
    background: var(--secondary_25);
    border-radius: 5px;
}

/* Resizer */
::-webkit-resizer {
    background: var(--secondary_5);
}

.show-on-hover, .show-on-parent-hover{
  opacity: 0;
}
:hover > .show-on-hover{
  opacity: 1;
}

:hover > * > .show-on-parent-hover{
  opacity: 1;
}
